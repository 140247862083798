import dayjs from "dayjs";
import {shortDateTimeFormat} from "../../utils/style/formats";
import {BatteryAlertIcon, BatteryFullIcon, BatteryHalfIcon, BatteryOffOutlineIcon} from "../../utils/icons";
import {Colors} from "../../utils/style/colors";
import {getI18n} from "react-i18next";
import {Tooltip} from "@mui/material";


const BatteryLevelToIcon = (level) => {
    if (!level) {
        return <BatteryOffOutlineIcon color={Colors.gray30}/>;
    }

    if (+level > 80) {
        return <BatteryFullIcon color={Colors.green}/>;
    } else if (+level > 30) {
        return <BatteryHalfIcon color={Colors.gray40}/>;
    } else {
        return <BatteryAlertIcon color={Colors.warning}/>;
    }
}

const DeviceBatteryIcon = ({level, lastUpdate}) => {
    let title;
    const t = getI18n().t;
    if (level) {
        title = `${level} %`;
        if (lastUpdate) {
            title += ` - ${t('lastUpdate')} ${dayjs.unix(lastUpdate).format(shortDateTimeFormat)}`;
        }
    } else {
        title = t('unavailable')
    }
    return (
        <Tooltip title={title}>
				<span>
					{BatteryLevelToIcon(level)}
				</span>
        </Tooltip>
    );
}

export default DeviceBatteryIcon;
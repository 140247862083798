import {scenarioTriggers} from "./scenarioTriggers";
import {
    AlarmCenterIcon,
    AlarmIcon,
    AssistanceRequestIcon, BatteryAlertIcon, BatteryChargingIcon, BatteryIcon, BuildingIcon, SleepIcon,
    ButtonIcon, ConfigIcon, EmailIcon, IOIcon, LocationEnterIcon, LocationExitIcon,
    RipcordIcon, ScenarioIcon, SendIcon, SMSIcon,
    TimerIcon, WebhookIcon,
    WifiStarIcon,
    WithoutAlarmIcon, MapMarkerCheckIcon
} from "../../icons";
import ManDownIcon from "../../../components/Icon/ManDownIcon";

export const triggerIcons = {
    [scenarioTriggers.allAlarmTypes]: AlarmIcon,
    [scenarioTriggers.genericAlarm]: AlarmIcon,
    [scenarioTriggers.manDown]: ManDownIcon,
    [scenarioTriggers.sosButton]: AlarmIcon,
    [scenarioTriggers.ripcord]: RipcordIcon,
    [scenarioTriggers.timer]: TimerIcon,
    [scenarioTriggers.alarmClosed]: WithoutAlarmIcon,
    [scenarioTriggers.assistanceRequestClosed]: AssistanceRequestIcon,
    [scenarioTriggers.connectionChanged]: WifiStarIcon,
    [scenarioTriggers.quickButton]: ButtonIcon,
    [scenarioTriggers.beaconBattery]: BatteryAlertIcon,
    [scenarioTriggers.geofenceEntered]: LocationEnterIcon,
    [scenarioTriggers.geofenceExited]: LocationExitIcon,
    // [scenarioTriggers.geofenceAttendance]: MapMarkerCheckIcon,
    [scenarioTriggers.idle]: SleepIcon,
    [scenarioTriggers.docked]: BatteryChargingIcon,
    [scenarioTriggers.undocked]: BatteryIcon,
    [scenarioTriggers.lowBattery]: BatteryAlertIcon,
    [scenarioTriggers.smsMessage]: SMSIcon,
    [scenarioTriggers.webhook]: WebhookIcon,
    [scenarioTriggers.ioChange]: IOIcon,
    [scenarioTriggers.espa]: IOIcon,
    [scenarioTriggers.email]: EmailIcon,
    [scenarioTriggers.configError]: ConfigIcon,
    [scenarioTriggers.multiTenant]: BuildingIcon,
    [scenarioTriggers.messageNotAcknowledged]: SendIcon,
    [scenarioTriggers.checkAssistanceRequestResponses]: AssistanceRequestIcon,
    [scenarioTriggers.arcAlarm]: AlarmCenterIcon,
    [scenarioTriggers.scenarioDisabled]: ScenarioIcon
};